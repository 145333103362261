import React from "react";
import { Link } from "react-router-dom";
import "./findrace.css";
import mobileBanner from "../../../assets/Mb_banner.jpg";
const FindRace = (props) => {
  const { heading, para, btn_link, btn_text, imageUrl } = props;

  return (
    <div>
      <div className="banner-container">
        <img
          src={
            imageUrl
              ? imageUrl
              : "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Banner+(1).jpg"
          }
          className="bannerImage"
        />
        <div className="text-container">
          <h1 className="banner-heading">{heading}</h1>
          <p className="banner-subheading">{para}</p>
          <div className="findrace-button">
            <Link
              to={btn_link ? btn_link : "/find-your-race"}
              style={{ textDecoration: "none" }}
            >
              <button>{btn_text ? btn_text : "Find Your Race"}</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="banner-container-mobile">
        <div className="text-container-mobile">
          <h1 className="banner-heading-mobile">{heading}</h1>
          <p className="banner-subheading-mobile">{para}</p>
          <div className="findrace-button-mobile">
            <Link
              to={btn_link ? btn_link : "/find-your-race"}
              style={{ textDecoration: "none" }}
            >
              <button>{btn_text ? btn_text : "Find Your Race"}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindRace;
