export const Race = [
    {
      id: 1,
      question: "What is an obstacle race?",
      answer:
        "As the name suggests, an obstacle race is a race where you not only run but also overcome military-style obstacles spread throughout the racetrack. At the Maruti Suzuki Arena Devils Circuit, the course is 5 km long and interspersed with 15 military-style obstacles. You can choose to attempt some or all, depending on your comfort level.",
    },
    {
      id: 2,
      question: "​​What is Maruti Suzuki Arena Devils Circuit?",
      answer:
        "Maruti Suzuki Arena Devils Circuit, arguably India’s most-awaited annual event, is the country's answer to the run-of-the-mill fitness events in Asia. Held in 10 cities between September and March, it draws health enthusiasts from a wide age and fitness spectrum who come to test their physical and mental strength on our five-kilometre race track. However, this is not a mini-marathon where you run on a flat stretch of land. Our track features 15 thrilling and fun obstacles of varying difficulty levels that require you to ‘Reach for the Sky,’ ‘Reach Across’ barriers, crawl under barbed wires, and jump into a pool filled with tons of ice cubes. Think old-school hurdle races, and multiply that by100. That’s the experience you’re looking at.",
    },
    {
      id: 3,
      question: "How is Maruti Suzuki Arena Devils Circuit different from other racing events? ",
      answer:
        "A racing event requires each participant to run a specific distance, for example, 3 km, 5 km, 10 km, 21 km, or 42 km. All participants who have chosen the same race distance typically start together at the start line and are not required to help each other complete the race. The focus for each person is on completing their race in the shortest possible time.<br/>The Maruti Suzuki Arena Devils Circuit, on the other hand, requires participants to not only run the 5 km distance but also overcome obstacles along the way. This often involves helping each other (in the non-competitive category), which fosters camaraderie and team bonding. Unlike a traditional running event, participants don’t all run together; instead, they run in groups that start at various intervals. These groups are called “Waves” and start at specific times of the day, much like a film show—same event, but different start times.",
    },
    {
      id: 4,
      question: "Who is it for?",
      answer: "It's for everyone! It doesn’t matter if it’s your first sports event ever. Come with your friends, family, or colleagues. Bring your old folks, your lazy acquaintances, and your super-fit gym buddies.",
    },
    {
      id: 5,
      question: "Is parking available at the venue?",
      answer: "Yes, ample parking is available at the circuit, but only for participants. To park, you will be required to show your BIB.",
    },
    {
      id: 6,
      question: "Where can I find my start time?",
      answer: "Once you’ve registered with your preferred wave, the confirmation e-mail will specify your wave and start time. Additionally, your start time will also be printed at the bottom right corner of your BIB.",
    },
    {
      id: 7,
      question: "At what time should I reach the venue?",
      answer: "You should aim to reach the venue at least 60-90 minutes prior to the start of your race.",
    },
    {
      id: 8,
      question: "Are spectators allowed at the venue?",
      answer: "Yes! Each participant can bring one spectator. You must come to the venue together and get his/her Spectator band from the registration counter against your BIB number.",
    },
    {
      id: 9,
      question: "Are spectators allowed on the course?",
      answer: "Spectators are strictly barred from entering the race track. There is ample space near the bunting to stand and cheer for the participant.",
    },
    {
      id: 10,
      question: "What should I wear to the Maruti Suzuki Arena Devils Circuit?",
      answer: "We urge you to wear the merchandise provided by us! We strongly recommend you to not wear any jewellery, accessories or carry any expensive items like watches, wallets, and mobile phones on the track. We will not be liable for any loss you may suffer during the event.",
    },
    {
      id: 11,
      question: "What should I bring to the event day?",
      answer: "a. Your running BIB (without this you will not be allowed to run under any circumstances).<br>b. Your wristband (without this you will not be allowed inside the venue).<br>c. Extra clothes if you want to want to change after the race. Lots of people remain in the race clothes too! It’s up to you.<br>d. Cash to purchase food and drinks with, as no credit/debit card facility will be available.<br>",
    },
    {
      id: 12,
      question: "Will drinking water be available?",
      answer: "Yes, complimentary drinking water will be available at multiple points across the course and at the village. We recommend you stay hydrated throughout your run.",
    },
    {
      id: 13,
      question: "Are shower facilities available on site?",
      answer: "No, we do not have shower facilities available. We recommend you carry a towel and extra set of clothes especially if you are travelling by private transport, as you will leave the event muddy and wet.",
    },
    {
      id: 14,
      question: "Can I bring friends along?",
      answer: "Yes, each ticket purchase allows one person to accompany the runner. All spectators are required to register on the event day and sign a waiver form at the venue.",
    },
  ];

 export const Registration = [
    {
      id: 1,
      question: "How do I choose my participation category?",
      answer: "Choose your participation category based on your objective. If you want to compete only with yourself, make wonderful memories, and enjoy a day filled with thrill and adventure, join the non-competitive category. If, however, you are competitive and want to test how many obstacles you can complete in the shortest time, the competitive category is for you. Both these categories require you to sign up for any city edition.<br> If you believe you can give everyone a run for their money and be the top contender on the course, then join the Competitive(Wave A), where you will need to enroll in at least six cities throughout the season.",
    },
    {
      id: 2,
      question: "Which category should I book?",
      answer: " Book for Wave A (Exclusive) if you wish to run in all cities and compete for the grand prize of a Maruti Suzuki Swift. Book the Competitive ticket if you wish to compete with Devil Slayers in your city and test your fitness level and stamina. Book non-competitive if you’re coming to have fun with friends. ",
    },
    {
      id: 3,
      question: "What is a Wave?",
      answer: "Unlike a traditional running event, participants don’t all run together; instead, they run in groups that start at various intervals. These groups are called “Waves” and start at specific times of the day, much like a film show. It’s the same event but with different start times.",
    },
    {
      id: 4,
      question: "What is included in the participation fee?",
      answer: "Find your race 'https://www.devilscircuit.com/find-your-race' and click on ‘More Info’ to know more!",
    },
    {
      id: 5,
      question: "Can I run without a bib?",
      answer: "No, you cannot. The bib identifies you as a valid participant at each event. The two items from your goody bag that you must have on you when you come for the DC race are your running bib and your participant wristband. Without these, you will be denied entry on race day.",
    },
    {
      id: 6,
      question: "How do I collect my BIB number and goodie bag?",
      answer: "Our BIB distribution drives typically take place on the Thursday, Friday, and Saturday prior to the Maruti Suzuki Arena Devils Circuit edition. You will receive information about the date of BIB Distribution on your email ID immediately after completing your registration. The venue and timing details will be sent to you one week before the event, to the same email ID.<br>Additionally, you will receive an upload link where you must upload your COVID vaccination certificate and ID proof. You will also be asked to digitally sign the Waiver. Once this process is successfully completed, you will receive a unique QR code. This QR code must be presented at the Bib Collection Centre to collect your running bib and goody bag.",
    },
    {
      id: 7,
      question: "What do I need to bring to the Bib Distribution Centre?",
      answer: "You need to show your QR code at the Bib collection centre to get your goody bag and running bib.",
    },
    {
      id: 8,
      question: "Can someone else collect the BIB and Goodie Bag on my behalf?",
      answer: "Yes, they can. They need to show us your QR code which you can send them by email or whatsapp.",
    },
    {
      id: 9,
      question: "Can I get the BIB couriered?",
      answer: "We have no courier service option this season.",
    },
    {
      id: 10,
      question: "How do I register a team?",
      answer: "You can purchase tickets in the same wave to run as a team.",
    },
    {
      id: 11,
      question: "Can my registration be transferred or cancelled?",
      answer: "Registrations cannot be cancelled. However, you may transfer your booking to someone else up until four days before the BIB Distribution. Once you’ve collected your BIB, you may not transfer it. Doing so will disqualify whoever is running on your BIB number.",
    },
    {
      id: 12,
      question: "Can I participate in multiple cities?",
      answer: "Yes you can, simply buy the tickets for the cities you wish to participate in.",
    },
    {
      id: 13,
      question: " Can I run DC twice on the same day?",
      answer: "Yes, you can simply buy the tickets for the cities you wish to participate in.",
    },
    {
      id: 14,
      question: "Can I run DC with my group?",
      answer: "Yes you can. Everyone who chooses the same “Wave” time, gets to run together. Just let your group know Thus, you all can run together.",
    },
    {
      id: 15,
      question:
        "Is there a difference in group booking versus individual booking?",
      answer: "As part of the group booking, you get to select the wave time for everyone on the group. As an individual you make the choice only for yourself. The experience of the race is exactly the same.",
    },
    {
      id: 16,
      question: "I bought a ticket for myself, what happens next?",
      answer: "Congratulations! You have already taken the first step towards a wonderful Sunday experience. On purchase of the ticket you would have received a confirmation mail from us, that is all you need. A few days post this, we will send you a mail asking you to upload your ID proof, and sign the digital waiver. Once this is done you will receive a confirmation of documents being successfully uploaded along with a unique QR code. We will then send you details of the Bib Distribution with dates and venue- here is where you can come to collect your t-shirt, bib, and goody bag. You can also send someone on your behalf to collect these, as long as you share your QR code with them.",
    },
    {
      id: 17,
      question: "I bought multiple tickets for my group, what happens next?",
      answer: "Congratulations! You have already taken the first step towards a wonderful Sunday experience for your group! . On purchase of the ticket you would have received a confirmation mail from us, that is all you need. A few days post this we will send you an email with a link you can share with all members of your group to fill in their own details and complete the registration process. We will send you a mail asking each one of the group to upload their ID proof, and sign the digital waiver. Once this is done each participant will receive a confirmation of documents being successfully uploaded along with a unique QR code. We will then send the details of the Bib Distribution with dates and venue- here is where everyone can come to collect their t-shirt, bib, and goody bag. You can also send someone on your behalf to collect these, as long as you share your QR code with them.",
    },
    {
      id: 18,
      question: "What age group is the junior race for?",
      answer: "Junior race is for the children of age from 12 to 16.",
    },
  ];

 export const Prize = [
    {
      id: 1,
      question: "How can I win the car?",
      answer: "To win the car, you must register for Wave A (Exclusive) and compete in a minimum of 6 city editions. If you are at the top of the table at the end of the season in either the  Men’s or the Women’s category then the car is yours!",
    },
    {
      id: 2,
      question: "How do I get my timing certificate?",
      answer: "The timing certificates are available only to the participants in the competitive category. These shall be emailed to you at the email ID provided at the time of registration, a week post the event.",
    },
    {
      id: 3,
      question: "What prizes can I win in the competitive category?",
      answer: "To know about prize in competitive category click here {link}",
      link: "/categories",
    },
    {
      id: 4,
      question: "What do I get as a prize in the non competitive category?",
      answer: "For non-competitive category you can claim your medal at the finish line!!",
    },
    // {
    //   id: 5,
    //   question: "What prizes are given in the junior category?",
    //   answer: "",
    // },
  ];