import React from 'react';
import PageHead from '../common/pageHead/pageHead';
import Banner from '../categories/banner';
import MovingBanner from '../common/movingBanner/movingBanner';
import FindRace from '../common/findRaceFooter/findrace';
import "../categories/corporate.css";
// import "./corporate.css";
const data = {
  
  marketing: [
    {
      heading: "Corporate Participation",
      marketing: "So whether you have already experienced the magic of Maruti Suzuki Arena Devils Circuit, or you are a newbie, reach out to us and we will help you register your colleagues. Over 1000 corporate organisations have had their employees join the tribe of devilslayers in the last decade, here is why! ",
      // image_url: "path/to/your/image.jpg", // Uncomment if needed
    }
  ]
};

const CorporatePage = () => {
  return (
    <>
    <PageHead
      page_name={data.marketing[0].heading}
      text_1={data.marketing[0].marketing}
    />
    <div className='bannerDesc'>
            <p>Adrenaline Rush - Ignite excitement and energy with thrilling obstacle course challenges.</p>
            {/* <p>Ignite excitement and energy with thrilling obstacle course challenges.</p> */}
          
            <p>Unity Building - Strengthen team cohesion through collaborative problem-solving and support.</p>
            {/* <p>Strengthen team cohesion through collaborative problem-solving and support.</p> */}
          </div>
    <Banner/>
    <FindRace
        heading="What's Included!"
        para="Gear up with a branded t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious."
        btn_link={"/whatsIncluded"}
        btn_text="Know More"
        imageUrl="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Whats+included.webp"
      />
    <div className="corporateContainer">
        <h1>Add-ons You Can Purchase</h1>
        <div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/tshirt+(2)_11zon.jpg"
              alt="shirt"
            />
            <p>T-shirt Branding</p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>

          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/corporate+(3)+copy.webp"
              alt="corporate"
            />
            <p>Corporate Banner</p>
            <div>
              ₹ 2000 <span> /corporate</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Rectangle+419+(1).png"
              alt="shirt"
            />
            <p>Corporate Trophy </p>
            <div>
              ₹ 3000 <span> /corporate</span>
            </div>            </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/management.png"
              alt="shirt"
            />
            <p>Flag Off by Management</p>
            <div>
             Above 200 &nbsp;<span>participants</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/exclusive+(2)+copy.webp"
              alt="shirt"
            />
            <p>Exclusive Wave</p>
            <div>
            Above 350 &nbsp;<span>participants</span>
            </div>
          </div>

          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/Rectangle+419+(2).png"
              alt="shirt"
            />
            <p>Logo on BIB </p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Rectangle+419+(2).png"
              alt="shirt"
            />
            <p>Digital Certificates to All</p>
            <div>
              ₹ 5000<span> /corporate</span>
            </div>
          </div>
          <div>
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/mealCoupon.png"
              alt="shirt"
            />
            <p>Meal Coupon</p>
            <div>
              ₹ 150<span> /participant</span>
            </div>
          </div>
        </div>
      </div>
      <MovingBanner showBorder={false} />
    </>
    
  );
}

export default CorporatePage;
