export const leaderboard = {
    leaderboardhead: "History in the Making",
    leaderboardubtext:
        "Discover the top 10 male and female runners at Maruti Suzuki Arena Devils Circuit, showcasing the pinnacle of strength and agility. Join the Competitive category to test your mettle and aim for the leaderboard.",
    malehead: "Male Participants",
    femalehead: "Female Participants",
    twothree: {
        male: [
            {
                id: 1,
                points: 143,
                name: "Md. Aseem Khan",
            },
            {
                id: 2,
                points: 110,
                name: "Rishav Karki",
            },
            {
                id: 3,
                points: 87,
                name: "Gagandeep Chopra",
            },
            {
                id: 4,
                points: 64,
                name: "Tiruvan Kumar",
            },
            {
                id: 5,
                points: 53,
                name: "Abhijeet Ghadge",
            },
            {
                id: 6,
                points: 38,
                name: "Vaibhav Thapliyal",
            },
            {
                id: 7,
                points: 25,
                name: "Vinoth Sankar",
            },
            {
                id: 8,
                points: 14,
                name: "Brendon",
            },
            {
                id: 9,
                points: 12,
                name: "Sanjay Negi",
            },
            {
                id: 10,
                points: 10,
                name: "Sajjid Ali",
            },
        ],
        female: [
            {
                id: 1,
                points: 150,
                name: "Zahabiya Merchant",
            },
            {
                id: 2,
                points: 110,
                name: "Trushna Joshi",
            },
            {
                id: 3,
                points: 55,
                name: "Isha Sharma",
            },
            {
                id: 4,
                points: 36,
                name: "Krishna Shah",
            },
            {
                id: 5,
                points: 25,
                name: "Ritu Kumari",
            },
            {
                id: 6,
                points: 25,
                name: "Ekta",
            },
            {
                id: 7,
                points: 18,
                name: "Avishka Gupta",
            },
            {
                id: 8,
                points: 16,
                name: "Ameya Ramdas",
            },
            {
                id: 9,
                points: 16,
                name: "Sanju Bishnoi",
            },
            {
                id: 10,
                points: 15,
                name: "Madhuri Kudva",
            },
        ]
    },
    twotwo: {
        male: [
            {
                id: 1,
                points: 164,
                name: "Sukhchain Singh",
            },
            {
                id: 2,
                points: 112,
                name: "Kasim khan",
            },
            {
                id: 3,
                points: 104,
                name: "Sunil Phogat",
            },
            {
                id: 4,
                points: 63,
                name: "Shreyas Rao",
            },
            {
                id: 5,
                points: 60,
                name: "Yadhu Krishnan",
            },
            {
                id: 6,
                points: 56,
                name: "Undigairaman",
            },
            {
                id: 7,
                points: 34,
                name: "Rishav Karki",
            },
            {
                id: 8,
                points: 34,
                name: "Vikramsagar KA",
            },
            {
                id: 9,
                points: 29,
                name: "Anish Damodara Shetty",
            },
            {
                id: 10,
                points: 28,
                name: "Abhijeet Ghadge",
            },
        ],
        female: [
            {
                id: 1,
                points: 193,
                name: "Zahabiya Merchant",
            },
            {
                id: 2,
                points: 145,
                name: "Jyothi poojari",
            },
            {
                id: 3,
                points: 114,
                name: "Aarti Juikar",
            },
            {
                id: 4,
                points: 68,
                name: "Trushna joshi",
            },
            {
                id: 5,
                points: 37,
                name: "Ekta Rawat",
            },
            {
                id: 6,
                points: 33,
                name: "Mallika Fernandes",
            },
            {
                id: 7,
                points: 32,
                name: "Madhuri Kudva",
            },
            {
                id: 8,
                points: 20,
                name: "Dorotheus Matilda Ellens",
            },
            {
                id: 9,
                points: 12,
                name: "Bhumika Kashyap",
            },
            {
                id: 10,
                points: 10,
                name: "Ritu kumari",
            },
        ]
    },
};
