import './master.css';
import FindRace from '../common/findRaceFooter/findrace';
function Master() {
    return (
        <>
            <div className="masterContainer">
                <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Masters.jpg" alt="Master" />
                <div>
                    <p>The master category is exclusively for participants aged 40 and above. This category is designed to celebrate the strength, resilience, and experience of our seasoned participants. Whether you're an avid runner, fitness enthusiast, or just looking to overcome the course, you have a chance to challenge yourself and enjoy a day of camaraderie and achievement. Prove that age is just a number and cross the finish line to claim your well-deserved medal. This category is perfect for those who want to compete at a pace that respects their experience while still embracing the thrill of the challenge.</p>
                    <p>Register yourself in this category, come run your race, enjoy the mud, water, and ice! And yes, claim your medal at the finish line!!</p>
                </div>
                <div>
                        <h1>Masters Category:</h1>
                        <table className="table">
                            <tr>
                                <th>Winner</th>
                                <td>Winners Flag + Amazon Voucher worth INR 5000</td>
                            </tr>
                        </table>
                    </div>
            </div>
            <FindRace heading="Unleashing the Power of Resistance!" para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance." btn_link={"/find-your-race"} btn_text="Find Your Race" />

        </>
    )
}
export default Master;