import React from "react";
import "./obstacleList.css";
import obstacleImage from "../../assets/underline.svg"; // Adjust the path to your image

const obstacles = [
  { id: 1, name: "Commando Crawl", desc: "Description for obstacle 1", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/commandow-crawl-01-668d26c702ff1.webp" },
  { id: 2, name: "Height of Hell", desc: "Description for obstacle 2", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/height-of-hell-01-668d26ca02ae6.webp" },
  { id: 3, name: "The Summit", desc: "Description for obstacle 3", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/the-summit-668d26db62b65.webp" },
  { id: 4, name: "Step Up", desc: "Description for obstacle 4", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/step-up-2-1-668d26d4be41f.webp" },
  { id: 5, name: "Slip Down", desc: "Description for obstacle 5", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/slip-down-668d26d3a7111.webp" },
  { id: 6, name: "Round Wooden Pole", desc: "Description for obstacle 6", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/round-wooden-pole-01-668d26d11740b.webp" },
  { id: 7, name: "Rope Climb", desc: "Description for obstacle 7", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/rope-climb-668d26d07ce27.webp" },
  { id: 8, name: "Monkey Bar", desc: "Description for obstacle 8", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/monkey-bar-01-668d26ca3d93e.webp" },
  { id: 9, name: "Door Walk", desc: "Description for obstacle 9", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/door-walk-01-668d26da1053f.webp" },
  { id: 10, name: "Wall Climb", desc: "Description for obstacle 10", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/wall-climb-668d26d6de7dd.webp" },
  { id: 11, name: "Roller Cross", desc: "Description for obstacle 11", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/roller-cross-668d26dd634b5.webp" },
  { id: 12, name: "Murder by Mud", desc: "Description for obstacle 12", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/murder-by-mud-668d26cda8e6a.webp" },
  { id: 13, name: "Monkey Crawl", desc: "Description for obstacle 13", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/monkey-crawl-668d26cd3e904.webp" },
  { id: 14, name: "Back Stack", desc: "Description for obstacle 14", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/back-stack-668d26d8264c8.webp" },
  { id: 15, name: "Brain Freeze", desc: "Description for obstacle 15", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/brain-freeze-668d26c6af831.webp" }
];

const ObstacleList = () => {
  return (
    <div className="outerObstacleListDiv">
      <div className="obstacleHeading">
        <span className="yearText">2024-25</span> Obstacles
      </div>
      <div className="obstacleImageContainer">
        <img src={obstacleImage} alt="Underline" className="headingImage" />
      </div>
      <div className="obstacleCardContainer">
        {obstacles.map(obstacle => (
          <div key={obstacle.id} className="obstacleCard">
            <img src={obstacle.img} alt={obstacle.name} className="obstacleImage" />
            <h3 className="obstacleName">{obstacle.name}</h3>
            {/* <p className="obstacleDesc">{obstacle.desc}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ObstacleList;
