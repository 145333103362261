import PageHead from '../common/pageHead/pageHead';
import './venue_partner.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { data } from '../../../src/assets/cateories/data';
function VenuePartnership() {
    console.log(data.venue[1].subheading)
    const images = [
        require('../../assets/venuepartner/prestige.png'),
        require('../../assets/venuepartner/unitech.png'),
        require('../../assets/venuepartner/vaishnaoi.png'),
        require('../../assets/venuepartner/amanora.png'),
        require('../../assets/venuepartner/Hiranandani.png'),
        require('../../assets/venuepartner/dosti.png'),
    ];
    return (
        <>

            <PageHead
                page_name={data.venue[0].heading}
                text_1={data.venue[0].venue}
                media={data.venue[0].image_url}
                media_type="image"
            />
            <div className="venueContainer">
                <h1>{data.venue[1].subheading[0].heading}</h1>
                <ul>
                    <li>{data.venue[1].subheading[0].text1}</li>
                    <li>{data.venue[1].subheading[0].text2}</li>
                    <li>{data.venue[1].subheading[0].text3}</li>
                    <li>{data.venue[1].subheading[0].text4}</li>
                </ul>
                <p>{data.venue[1].subheading[0].text5} <span>{data.venue[1].subheading[0].email}</span>{data.venue[1].subheading[0].text6}</p>
            </div>
            <div>
                <div className='venueHeading'>
                Trusted Venue Partners
                </div>
                <div className="movingBannerVenue">
            <div class="sliderBannerVenue">
                <div className="slide-track-venue">
                    {images.map((image, index) => (
                        <div className="slide" key={index}>
                            <img src={image} height="100" width="250" alt="" />
                        </div>
                    ))}
                    {images.map((image, index) => (
                        <div className="slide" key={index}>
                            <img src={image} height="100" width="250" alt="" />
                        </div>
                    ))}
                    {images.map((image, index) => (
                        <div className="slide" key={index}>
                            <img src={image} height="100" width="250" alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
            </div>


        </>
    )
}
export default VenuePartnership;