export const photos = {
  photoshead: "Go Down The Memory Lane",
  photosubtext:
    "Hello devilslayers! So, you’ve written your DC story and are waiting to get your hands on those photographs now. Well, here they are! Find your city and use your selfie to download your images, pronto!",

  twothree: [
    {
      id: 1,
      city: "Indore",
      link: "https://devilscircuitmumbai.runnertag.site/#/",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1.webp"
    },
    {
      id: 2,
      city: "Mumbai",
      link: "https://punemarutisuzukidevilscircuit.runnertag.site/#/",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/5.webp"
    },
    {
      id: 3,
      city: "Ahmedabad",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/3.webp"
    },
    {
      id: 4,
      city: "Pune",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/4.webp"
    },
    {
      id: 5,
      city: "Bengaluru",
      link: "https://memzo.co/m/Maruti-Suzuki-Arena-Devils-Circuit-Hyderabad-2024-24989/8417", 
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/2.webp"
    },
    {
      id: 6,
      city: "Hyderabad",
      link: "https://memzo.co/m/Maruti-Suzuki-Arena-Devils-Circuit--Goa-2024-24960/9434",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/6.webp"
    },
    {
      id: 7,
      city: "Kochi",
      link: "https://memzo.co/m/Maruti-Suzuki-Arena-Devils-Circuit-Chennai---2024-24161/8834",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/7.webp"
    },
    {
      id: 8,
      city: "Chennai",
      link: "https://snapd.me/search-photos/?eventId=dc4fa133-aebb-408b-939a-fd1f483ef8b3",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1440x750-6-66741dd0c971c.webp"
    },
    {
      id: 9,
      city: "Mohali",
      link: "https://memzo.co/m/Maruti-Suzuki-Arena-Devils-Circuit-Chennai---2024-24161/8834",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/c84c0b_b574ec233ac049afadb38ce23c488ba0~mv2_11zon.webp"
    },
    {
      id: 10,
      city: "Delhi",
      link: "https://snapd.me/search-photos/?eventId=dc4fa133-aebb-408b-939a-fd1f483ef8b3",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/c84c0b_c2350d2421d242b8a0dc2ace92860a97~mv2.webp"
    },
  ]
};
