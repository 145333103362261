import React, { useEffect, useState } from "react";
import "../faq/faq.css";

import AddIcon from "../../assets/faq/add.svg";
import MinusIcon from "../../assets/faq/minus.svg";
import PageHead from "../common/pageHead/pageHead";
import band from "../../assets/homepage/newband.png";
import { Race } from "./FaqData";
import { Registration } from "./FaqData";
import { Prize } from "./FaqData";

const Faq = () => {
  const [currentTab, setCurrentTab] = useState("race");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showMapData, setShowMapData] = useState([]);

  const handleState = (data) => {
    setCurrentTab(data);
    setCurrentQuestion(0);
  };

  useEffect(() => {
    if (currentTab === "registration") {
      setShowMapData(Registration);
    } else if (currentTab === "prize") {
      setShowMapData(Prize);
    } else {
      setShowMapData(Race);
    }
  }, [currentTab]);

  const para =
    "In the past 11 seasons, we’ve had all kinds of questions from our devilslayers. Read the answers below, we may have covered it!";

  return (
    <div className="faq_wrapper">
      <div className="faq_main_div">
        <PageHead
          page_name={"FAQ"}
          page_head={"Frequently Asked Questions"}
          text_1={para}
          text_2={null}
          text_3={null}
          media={
            "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/Framchise.jpg"
          }
          media_type="image"
        />

        <div className="faq_tabs_div">
          <div className="faq_tabs_inner_div">
            <div className="faq_tabs_header">
              <a
                onClick={() => handleState("race")}
                className={
                  currentTab === "race" ? "faq_tab_active" : "faq_tab_inactive"
                }
              >
                Race
              </a>
              <a
                onClick={() => handleState("registration")}
                className={
                  currentTab === "registration"
                    ? "faq_tab_active"
                    : "faq_tab_inactive"
                }
              >
                Registration
              </a>
              <a
                onClick={() => handleState("prize")}
                className={
                  currentTab === "prize" ? "faq_tab_active" : "faq_tab_inactive"
                }
              >
                Prize
              </a>
            </div>

            <div className="faq_cards">
              {showMapData.map((item) => {
                const formattedAnswer = item.answer.replace(
                  "{link}",
                  `<a href=${item.link} rel="noopener noreferrer">here</a>`
                );

                return (
                  <div className="faq_card" key={item.id}>
                    <div
                      className="faq_card_question_div"
                      onClick={() =>
                        setCurrentQuestion(
                          currentQuestion === item.id ? 0 : item.id
                        )
                      }
                    >
                      <p className="faq_card_question">{item.question}</p>
                      <div>
                        {currentQuestion === item.id ? (
                          <img
                            src={MinusIcon}
                            alt="icon"
                            className="faq_card_arrow"
                          />
                        ) : (
                          <img
                            src={AddIcon}
                            alt="icon"
                            className="faq_card_arrow"
                          />
                        )}
                      </div>
                    </div>
                    {currentQuestion === item.id && (
                      <div
                        className="faq_card_answer_div"
                        dangerouslySetInnerHTML={{ __html: formattedAnswer }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <p className="faq_dont_find_p">
              Don't find answers to your questions? Drop us a DM on our Facebook
              or Instagram page and we'll get back to you.
            </p>
          </div>
        </div>
      </div>
      <div className="band_img_div_faq">
          <img src={band} alt="band" loading="lazy" />
        </div>
      {/* <div className="faq_obstacles_div"></div> */}
    </div>
  );
};

export default Faq;