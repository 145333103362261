import './non_comp.css';
import FindRace from '../common/findRaceFooter/findrace';
function Non_Comp() {
    return (
        <>
            <div className="nonCompContainer">
                <img src=" https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/NonCompcategory.webp" alt="non_comp" />
                <div>
                    <p>A large section of the devilslayer community participates in the non compettive category. Built as a section where the race is not timed and scaling all obstacles is not mandatory, this is a category for you if you want to test yourself and figure out where you stand. Enjoy a great experience with your friends, running groups, and social clubs, or just enjoy a day that you will never forget!</p>
                    <p>Register yourself in this category, come run your race, enjoy the mud, water, and ice! And yes, claim your medal at the finish line!!</p>
                </div>
            </div>
            <FindRace heading="Unleashing the Power of Resistance!" para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance." btn_link={"/find-your-race"} btn_text="Find Your Race" />

        </>
    )
}
export default Non_Comp;