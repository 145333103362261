import React, { useState } from "react";
import "../contactUs/contactUs.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import band from "../../assets/homepage/newband.png";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import PageHead from "../common/pageHead/pageHead";
 
const ContactUs = () => {
  const [spinState, setSpinState] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Name should be atleast 2 characters")
      .max(100, "Name should be less 100 characters")
      .required("Name is required"),
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    Mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
      .required("Mobile Number is required"),
    Company: Yup.string()
      .min(2, "Company should be atleast 2 characters")
      .max(100, "Company should be less 100 characters"),
      // .required("Company is required"),
    Position: Yup.string()
      .min(2, "Position should be atleast 2 characters")
      .max(100, "Position should be less 100 characters"),
      // .required("Position is required"),
    Address: Yup.string()
      .min(2, "Address should be atleast 2 characters")
      .max(100, "Address should be less 100 characters"),
      // .required("Address is required"),
    Message: Yup.string()
      .min(2, "Message should be atleast 2 characters")
      .max(500, "Message should be less 500 characters"),
      // .required("Message is required"),
  });

  const initialValues = {
    fullName: "",
    Email: "",
    Mobile: "",
    Company: "",
    Position: "",
    Address: "",
    Message: "",
  };

  const handleSubmit = async (values) => {
    setSpinState(true);

    try {
      await axios.post("https://submit-form.com/t3OCaoWTy", values);
      setSpinState(false);
      toast.success("Message submited successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const preventArrowKeyIncrement = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  return (
    <div className="contact_wrapper">
      <div className="contact_main_div">
 
        <PageHead
         page_name={"Contact US"} 
         page_head={"Get in Touch"} 
         text_2={null}
        text_3={null}
        media={
          "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Contact%2BUs_11zon.webp"
        }
        media_type="image"
          />

        <div className="contact_form_main_div">
          <div className="contact_form_div"> 
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="contact_form">
                    <div className="contact_field_box">
                      <label htmlFor="fullName">Name*</label>
                      <Field
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Email">Email*</label>
                      <Field
                        type="email"
                        id="Email"
                        name="Email"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Email"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Mobile">Mobile*</label>
                      <Field
                        type="number"
                        id="Mobile"
                        name="Mobile"
                        className="contact_field"
                        onKeyDown={preventArrowKeyIncrement}
                      />
                      <ErrorMessage
                        name="Mobile"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Company">Company</label>
                      <Field
                        type="text"
                        id="Company"
                        name="Company"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Company"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Position">Position</label>
                      <Field
                        type="text"
                        id="Position"
                        name="Position"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Position"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_field_box">
                      <label htmlFor="Address">City</label>
                      <Field
                        type="text"
                        id="Address"
                        name="Address"
                        className="contact_field"
                      />
                      <ErrorMessage
                        name="Address"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>

                    <div className="contact_text_area">
                      <label htmlFor="Message">Leave us a message</label>
                      <Field
                        as="textarea"
                        type="text"
                        id="Message"
                        name="Message"
                        className="contact_text_area_field"
                      />
                      <ErrorMessage
                        name="Message"
                        component="div"
                        className="contact_invalid_feedback"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="contact_join_btn_div"
                    style={{
                      cursor: !spinState ? "pointer" : "none",
                    }}
                  >
                    <div className="contact_join_btn_inner_div">
                      {spinState ? (
                        <CircularProgress
                          className="custom-progress"
                          style={{ color: "white" }}
                          size={25}
                          thickness={3}
                        />
                      ) : (
                        <span>Submit</span>
                      )}
                    </div>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="band_img_div_contact">
          <img src={band} alt="band" loading="lazy" />
        </div>
        {/* <div className="contact_obstacles_div"></div> */}
      </div>

      <ToastContainer />
    </div>
  );
};

export default ContactUs;
