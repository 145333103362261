import { lazy, Suspense, useEffect } from "react";
import "./homepage.css";
import prize from "../../assets/homepage/prize.png";
import MovingBanner from "../common/movingBanner/movingBanner";
import BannerVideo from "./BannerVideo";
import InstaFeed from "./InstaPost";
import TopSlider from "./TopSlider";
import FindRace from "../common/findRaceFooter/findrace";
import band from "../../assets/homepage/newband.png";
import tag from "../../assets/homepage/tag.png";
import Proud from "./proud/Proud";
import { useSearchParams } from "react-router-dom";

const City = lazy(() => import("./City"));
const Playground = lazy(() => import("./playground"));
const CityCard = lazy(() => import("./CityCard"));
const Slider = lazy(() => import("./Slider"));

const HomePage = () => {
  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const utmId = searchParams.get("utm_id");

  sessionStorage.setItem("utm_source", utmSource);
  sessionStorage.setItem("utm_medium", utmMedium);
  sessionStorage.setItem("utm_campaign", utmCampaign);
  sessionStorage.setItem("utm_id", utmId);
  return (
    <div className="homepage-container">
      <Suspense fallback={<div>Loading...</div>}>
        <TopSlider />
        <div className="tagDiv">
          <img src={tag} />
        </div>
        <div className="prize-div"></div>
        <div className="prize-image">
          <img src={prize} alt="Image 1" loading="lazy" />
        </div>
        <City />
        <div className="band_img_div">
          <img src={band} alt="band" loading="lazy" />
        </div>
        <div className="leaderboardbanner">
          <img
            src={
              "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/leaderboardbf961c4b05e4b34fd870-1-668fb43e2f150.webp"
            }
            alt="Leaderboard"
            loading="lazy"
          />
        </div>
        <div className="dividerband">Find Your Race</div>
        <CityCard />
        <div className="includedBanner">
          <img
            src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/bannernew_11zon.jpg"
            alt="included banner"
            loading="lazy"
          />
        </div>
        <div className="dividerband2">What You Get</div>
        <Slider />
        <FindRace
          heading={"Our Corporate Warriors"}
          para={
            "With a reputation for delivering adrenaline-pumping experiences and fostering team spirit, Devils Circuit is the top choice for corporate organisations seeking unique and engaging team-building activities. If you are responsible for employee engagement, reach out to us and we will show you how this is the best gift you can give to your colleagues! Don’t believe us? See how many marquee organisations have loved the experience!"
          }
          btn_link={"/corporate"}
          btn_text={"Know More"}
          imageUrl={
            "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/findraceimg8123d0afd186f5c05585-668fb3a78c3d6.webp"
          }
        />
        <MovingBanner showBorder={true} />
        <Playground showJunior={true} />
        <div className="dividerband2">Be a Devilslayer</div>
        <BannerVideo />
        <InstaFeed />
        <Proud />
      </Suspense>
    </div>
  );
};

export default HomePage;
