import React, { useState } from "react";
import "../header/header.css";
import Logo from "../../../assets/logo/newLogo.svg";
import WhiteLogo from "../../../assets/logo/newWhiteLogo.svg";
import { Link } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Drawer from "@mui/material/Drawer";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDevilslayerList, setShowDevilslayerList] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(null); // State for tracking open tooltip

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Toggle tooltip visibility
  const handleTooltipToggle = (tooltip) => {
    setOpenTooltip(openTooltip === tooltip ? null : tooltip);
  };

  return (
    <div className="header_wrapper">
      <div className="header_left">
        {!showDrawer && (
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={Logo} alt="logo" className="header_logo" />
          </Link>
        )}
      </div>

      <div className="header_hamburger_btn_div">
        <HiOutlineMenuAlt4
          className="header_hamburger_icon"
          onClick={() => setShowDrawer(!showDrawer)}
        />
      </div>

      <div className="header_right">
        <div className="header_links">
          <p onClick={handleClick} className="header_link">
            Devilslayer
            {anchorEl === null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </p>
          <Popover
            className="header_popover"
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="header_popover_div">
              <Link
                className="header_popover_link"
                style={{ textDecoration: "none" }}
                to="/village"
                onClick={handleClose}
              >
                Village
              </Link>
              <Link
                className="header_popover_link"
                style={{ textDecoration: "none" }}
                to="/community"
                onClick={handleClose}
              >
                Community
              </Link>
              <Link
                className="header_popover_link"
                style={{ textDecoration: "none" }}
                to="/photos"
                onClick={handleClose}
              >
                Photographs
              </Link>

              <Link
                className="header_popover_link"
                style={{ textDecoration: "none" }}
                to="/leaderboard"
                onClick={handleClose}
              >
                Leaderboard
              </Link>
            </div>
          </Popover>

            <Link to="/obstacles"
              className="header_link"
              style={{ textDecoration: "none" }}
            >
              Obstacles
            </Link>
          <Tooltip
            title="Coming Soon"
            placement="bottom-start"
            open={openTooltip === "train"}
            onClose={() => handleTooltipToggle(null)}
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                    {
                      marginTop: "5px",
                    },
                },
              },
            }}
          >
            <a
              className="header_link"
              style={{ textDecoration: "none" }}
              onClick={() => handleTooltipToggle("train")}
              onMouseEnter={() => handleTooltipToggle("train")}
              onMouseLeave={() => handleTooltipToggle(null)}
            >
              Train
            </a>
          </Tooltip>
          
          <Tooltip
            title="Coming Soon"
            placement="bottom-start"
            open={openTooltip === "shop"}
            onClose={() => handleTooltipToggle(null)}
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                    {
                      marginTop: "5px",
                    },
                },
              },
            }}
          >
            <a
              className="header_link"
              style={{ textDecoration: "none" }}
              onClick={() => handleTooltipToggle("shop")}
              onMouseEnter={() => handleTooltipToggle("shop")}
              onMouseLeave={() => handleTooltipToggle(null)}
            >
              Shop
            </a>
          </Tooltip>

          <Link
            className="header_find_your_race_btn"
            style={{ textDecoration: "none" }}
            to="/find-your-race"
          >
            Find your race
          </Link>
        </div>
      </div>

      {showDrawer && (
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          onOpen={() => setShowDrawer(true)}
        >
          <div className="header_drawer_main_div">
            <div className="drawer_header">
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  src={WhiteLogo}
                  alt="logo"
                  className="drawer_header_logo"
                />
              </Link>
              <div>
                <IoCloseSharp
                  onClick={handleDrawerClose}
                  className="header_cancel_icon"
                />
              </div>
            </div>

            <div className="header_drawer_links_div">
              <div>
                <p
                  onClick={() => setShowDevilslayerList(!showDevilslayerList)}
                  className="drawer_sub_links_toggler"
                >
                  Devilslayer
                  {showDevilslayerList === false ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </p>
                {showDevilslayerList && (
                  <div className="drawer_sub_links_div">
                    <Link
                      className="drawer_sub_link"
                      style={{ textDecoration: "none" }}
                      to="/village"
                      onClick={handleDrawerClose}
                    >
                      Village
                    </Link>
                    <Link
                      className="drawer_sub_link"
                      style={{ textDecoration: "none" }}
                      to="/community"
                      onClick={handleDrawerClose}
                    >
                      Community
                    </Link>
                    <Link
                      className="drawer_sub_link"
                      style={{ textDecoration: "none" }}
                      to="/photos"
                      onClick={handleDrawerClose}
                    >
                      Photographs
                    </Link>

                    <Link
                      className="drawer_sub_link"
                      style={{ textDecoration: "none" }}
                      to="/leaderboard"
                      onClick={handleDrawerClose}
                    >
                      Leaderboard
                    </Link>
                  </div>
                )}
              </div>
         
                <Link to="/obstacles"
                  className="header_drawer_link"
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerClose}
                >
                  Obstacles
                </Link>
             
              <a>
                <Tooltip
                  title="Coming Soon"
                  placement="bottom-start"
                  open={openTooltip === "train2"}
                  onClose={() => handleTooltipToggle(null)}
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: "0px",
                          },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                          {
                            marginBottom: "0px",
                          },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                          {
                            marginLeft: "0px",
                          },
                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                          {
                            marginRight: "0px",
                          },
                      },
                    },
                  }}
                >
                  <p
                    className="header_drawer_link"
                    style={{ textDecoration: "none" }}
                    onClick={() => handleTooltipToggle("train2")}
                  >
                    Train
                  </p>
                </Tooltip>
              </a>
              <Tooltip
                title="Coming Soon"
                placement="bottom-start"
                open={openTooltip === "shop2"}
                onClose={() => handleTooltipToggle(null)}
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                        {
                          marginTop: "0px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "0px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "0px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                        {
                          marginRight: "0px",
                        },
                    },
                  },
                }}
              >
                <p
                  className="header_drawer_link"
                  style={{ textDecoration: "none" }}
                  onClick={() => handleTooltipToggle("shop2")}
                >
                  Shop
                </p>
              </Tooltip>
              <Link
                className="header_drawer_btn"
                style={{ textDecoration: "none" }}
                to="/find-your-race"
                onClick={handleDrawerClose}
              >
                Find your race
              </Link>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Header;
